import { lazy, Suspense } from 'react';
import Fallback from '../custom/fallback';
const MainSection = lazy(() => import('../components/otpVerification/main'));

const OtpVerification = () => {
	return (
		<Suspense fallback={<Fallback />}>
			<MainSection />
		</Suspense>
	);
};

export default OtpVerification;
