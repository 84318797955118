import { lazy, Suspense, useEffect } from 'react';
import Fallback from '../custom/fallback';
import { logoutUser } from '../utils/logout';
const Main = lazy(() => import('../components/signIn/main'));

const SignIn = () => {
	useEffect(() => {
		logoutUser();
	}, []);

	return (
		<Suspense fallback={<Fallback />}>
			<Main />
		</Suspense>
	);
};

export default SignIn;
