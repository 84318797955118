import { lazy, Suspense } from 'react';
import Fallback from '../custom/fallback';
import DashboardLayout from './dashboardLayout/dashboardLayout';

const Main = lazy(() => import('../components/payouts/main'));

const Payouts = () => {
	return (
		<DashboardLayout heading='CyberPay Merchant / Payouts'>
			<Suspense fallback={<Fallback />}>
				<Main />
			</Suspense>
		</DashboardLayout>
	);
};

export default Payouts;
