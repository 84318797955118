import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap'); */
  :root {

    font-size: 62.5%;
    
    --color-primary: #2F8ED6;
    --color-primary-s: #2C82C4;
    --color-primary-m: #3D8CD6;
    --color-primary-l: #3B769E;
    --color-secondary: #000000;
    --color-secondary-s: #333333;
    --color-secondary-m: #0F283C;
    --color-secondary-l: #4A4A4A;
    --color-secondary-ls: #4F4F4F;
    --color-secondary-lm: #616161;
    --color-secondary-ll: #969BA0;
    --color-tertiary: #C79960;
    --color-success: #5BC065;
    --color-danger: #D75884;
    --color-quatery: #FFFFFF;
    --color-quatery-s: #FBFBFB;
    --color-primary-bg: linear-gradient(118.62deg, #2F8ED6 -18.91%, rgba(15, 40, 60, 0.99) 272.88%);

    --font-w-n: 400;
    --font-w-r: 500;
    --font-w-b: 600;
    --font-w-bb: 700;

    --font-s-1: 0.1rem;
    --font-s-2: 0.2rem;
    --font-s-3: 0.3rem;
    --font-s-4: 0.4rem;
    --font-s-5: 0.5rem;
    --font-s-6: 0.6rem;
    --font-s-7: 0.7rem;
    --font-s-8: 0.8rem;
    --font-s-9: 0.9rem;
    --font-s-10: 1rem;
    --font-s-11: 1.1rem;
    --font-s-12: 1.2rem;
    --font-s-13: 1.3rem;
    --font-s-14: 1.4rem;
    --font-s-15: 1.5rem;
    --font-s-16: 1.6rem;
    --font-s-17: 1.7rem;
    --font-s-18: 1.8rem;
    --font-s-19: 1.9rem;
    --font-s-20: 2.0rem;
    --font-s-21: 2.1rem;
    --font-s-22: 2.2rem;
    --font-s-23: 2.3rem;
    --font-s-24: 2.4rem;
    --font-s-25: 2.5rem;
    --font-s-26: 2.6rem;
    --font-s-27: 2.7rem;
    --font-s-28: 2.8rem;
    --font-s-29: 2.9rem;
    --font-s-30: 3.0rem;
 }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: 'Poppins', sans-serif;
  background-color: var(--color-quatery);
}


.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #bdbdbd;
}

.circle-progress {
  stroke: var(--color-primary);
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 1.6rem;
  font-weight: 600;
}

.error {
	color: red;
	text-align: center;
	margin-block: 1.6rem;
	padding-inline: 1.6rem;
  font-weight: bold;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: var(--color-primary-s) !important;
  border-color: var(--color-primary-s) !important;
}


.ant-steps-item-finish .ant-steps-item-icon .anticon-check {
  color : var(--color-primary-s) !important;
  border-color: var(--color-primary-s) !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--color-primary-s) !important;
}

`;
