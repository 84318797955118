import { lazy, Suspense } from 'react';
import Fallback from '../custom/fallback';
import DashboardLayout from './dashboardLayout/dashboardLayout';

const Main = lazy(() => import('../components/updateProfile/main/main'));

const UpdateProfile = () => {
	const handleNext = () => {};

	return (
		<DashboardLayout heading='CyberPay Merchant / Get Started  /  Test Integration'>
			<Suspense fallback={<Fallback />}>
				<Main handleNext={handleNext} />
			</Suspense>
		</DashboardLayout>
	);
};

export default UpdateProfile;
