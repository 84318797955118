import { apiCall } from './apiCall';

interface Token {
	id: string;
	type: string;
	expiryDate: string;
	token: string;
	twoFactorEnabled: boolean;
}

type TokenModel = Token[] | [];

const refresh = async (token: string): Promise<void | false> => {
	try {
		const response = await apiCall({}).get(`/api/v1/account/RefreshToken?refreshToken=${token}`);

		if (!response?.data?.succeeded) {
			localStorage.removeItem('auth');
			window.location.replace('/');
		}

		localStorage.setItem('auth', JSON.stringify(response?.data?.data?.tokenModel));
	} catch (error: any) {
		localStorage.removeItem('auth');
		window.location.replace('/');
		return false;
	}
};

const refreshToken = (): void => {
	const tokenModel: TokenModel = JSON.parse(localStorage.getItem('auth') || '[]');

	if (tokenModel?.length === 0) {
		localStorage.removeItem('auth');
		window.location.replace('/');
	}

	const token = tokenModel[1].token;

	refresh(token);
};

export default refreshToken;
