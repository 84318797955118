import styled from 'styled-components';
interface Props {
	marginBottom?: string;
}

export const StyledHeader = styled.header<Props>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-block-end: 3rem;
	gap: 2rem;
	background: #fff;
	position: sticky;
	top: -3rem;
	z-index: 1;

	@media (min-width: 1014px) {
		margin-block-end: 2rem;
		gap: 0;
	}
`;

export const StyledHeading = styled.h2`
	color: var(--color-primary);
	font-size: var(--font-s-20);

	@media (max-width: 1014px) {
		display: none;
	}
`;

export const StyledSpan = styled.span`
	color: var(--color-secondary);
`;

export const Wrapper = styled.span`
	display: flex;
	align-items: center;
	gap: 1.2rem;
	justify-self: flex-end;
	margin-inline-start: auto;

	& p {
		font-size: var(--font-s-16);
		margin: 0;
		text-transform: capitalize;
	}
`;

export const HamburgerWrapper = styled('div')`
	cursor: pointer;
	background-color: orange;
`;

export const HamburgerMenu = styled.div`
	width: 4rem;
	height: 0.3rem;
	position: relative;
	background-color: var(--color-primary);
	z-index: 20;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: all 0.5s ease-in-out;
	cursor: pointer;

	&::before,
	&::after {
		width: 4rem;
		height: 0.3rem;
		background-color: var(--color-primary);
		content: '';
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: all 0.5s ease-in-out;
	}

	&::before {
		top: -1rem;
	}

	&::after {
		top: 1rem;
	}

	&.open {
		background-color: transparent;
	}

	&.open::before {
		transform: rotate(45deg);
	}

	&.open::after {
		transform: rotate(-45deg);
	}

	&.open::before,
	&.open::after {
		top: 0;
	}

	@media (min-width: 1014px) {
		display: none;
	}
`;

export const Overlay = styled.div`
	width: 100%;
	height: 100vh;
	background-color: var(--color-tertiary);
	position: fixed;
	opacity: 0;
	display: none;
	z-index: 10;
`;
