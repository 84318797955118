import { lazy, Suspense } from 'react';
import Fallback from '../custom/fallback';
const Main = lazy(() => import('../components/resetPassword/main'));

const ResetPassword = () => {
	return (
		<Suspense fallback={<Fallback />}>
			<Main />
		</Suspense>
	);
};

export default ResetPassword;
