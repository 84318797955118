import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface Profile {
	createdBy: string;
	dateCreated: string;
	dateModified: string;
	id: string;
	isLockedOut: boolean;
	lockOutDate: string;
	logonFailedCount: 0;
	modifiedBy: string;
	phoneNumber: string;
	twoFactorEnabled: boolean;
	userClients: UserClient[];
	userContactDetails: [];
	userId: string;
	userStatus: string;
	username: string;
}

interface UserClient {
	client: Client;
	createdBy: string;
	dateCreated: string;
	isActive: boolean;
	userId: string;
}
interface Client {
	businessCode: string;
	clientCode: string;
	clientDescription: string;
	clientName: string;
	clientReturnUrl: string;
	clientSecret: string;
	clientStatus: string;
	createdBy: string;
	dateCreated: string;
	domains: [];
	id: string;
	permissions: [];
	salt: string;
}
interface Businesses {
	active: boolean; //active property for switch business
	address?: string;
	businessCode: string;
	businnessEmail?: string;
	businessId?: number;
	businessName?: string;
	businessTypeId?: number;
	bvn?: string;
	category?: string;
	contactEmail?: string;
	disputeEmail?: string;
	phoneNumber?: string;
	request?: string;
	requiredBVN?: string;
	status?: string;
	supportEmail?: string;
	userBusinesses?: [];
	website?: string;
}

interface Status {
	hasIntegrationKey: boolean;
	isDocumentUploaded: boolean;
	isMainAccountCreated: boolean;
	isProfileUpdated: boolean;
}
export interface Integration {
	businessCode: string;
	businessName: string;
	businessStatus: string;
	channels: [];
	enableWebhookSendOtherStatus: boolean;
	integrationChannels: [{ integrationChannelId: number; integrationId: number; channelId: number; status: string }];
	integrationId: number;
	integrationKey: string;
	isDirectDebitNotify: boolean;
	isDynamicSpliting: boolean;
	publicKey: string;
	reuseAuthToken: boolean;
	status: string;
	transactions: [];
}
interface Wallet {
	accountNumber: string;
	availableBalance: number;
	bankCode: string;
	bookbalance: number;
	businessCode: string;
	createdOn: string;
	isEnableNip: boolean;
	transactions: [];
	walletCode: string;
	walletName: string;
	walletStatus: string;
	walletType: string;
}

export interface Business {
	bankAccount: string;
	bankSortCode: string;
	businessId: number;
	isMainAccount: boolean;
	status: string;
	subAccountId: number;
	subAccountName: string;
	walletCode: string;
	walletType: string;
}

interface State {
	businesses: Businesses;
	business: Business;
	integrations: Integrations;
	integration: Integration;
	wallet: Wallet;
	profile: Profile;
	status: Status;
}

type Integrations = Integration[];

const initialState: State = {
	businesses: {} as Businesses,
	business: {} as Business,
	integrations: [] as Integrations,
	integration: {} as Integration,
	wallet: {} as Wallet,
	profile: {} as Profile,
	status: {} as Status,
};

export const businessSlice = createSlice({
	name: 'business',
	initialState,
	reducers: {
		updateBusinesses: (state: State, action: PayloadAction<Businesses>) => {
			state.businesses = action.payload;
		},
		updateBusiness: (state: State, action: PayloadAction<Business>) => {
			state.business = action.payload;
		},
		updateIntegrations: (state: State, action: PayloadAction<Integrations>) => {
			state.integrations = action.payload;
		},
		updateIntegration: (state: State, action: PayloadAction<Integration>) => {
			state.integration = action.payload;
		},
		updateWallet: (state: State, action: PayloadAction<Wallet>) => {
			state.wallet = action.payload;
		},
		updateProfile: (state: State, action: PayloadAction<Profile>) => {
			state.profile = action.payload;
		},
		updateStatus: (state: State, action: PayloadAction<Status>) => {
			state.status = action.payload;
		},
		logout: (state: State) => {
			state.business = {} as Business;
			state.businesses = {} as Businesses;
			state.integrations = {} as Integrations;
			state.integration = {} as Integration;
			state.wallet = {} as Wallet;
			state.profile = {} as Profile;
			state.status = {} as Status;
		},
	},
});

export const {
	updateBusinesses,
	updateBusiness,
	updateIntegration,
	updateIntegrations,
	updateProfile,
	updateStatus,
	updateWallet,
	logout,
} = businessSlice.actions;
export default businessSlice.reducer;
