import { lazy, Suspense } from 'react';
import Fallback from '../custom/fallback';
import DashboardLayout from './dashboardLayout/dashboardLayout';
const Main = lazy(() => import('../components/subAccount/main'));

const SubAccount = () => {
	return (
		<DashboardLayout heading='CyberPay Merchant / Sub Account'>
			<Suspense fallback={<Fallback />}>
				<Main />
			</Suspense>
		</DashboardLayout>
	);
};

export default SubAccount;
