import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { IoIosLogOut } from 'react-icons/io';
import { ReactComponent as Logo } from '../../../svgs/cyberpay-logo.svg';

export const StyledNavabar = styled.nav`
	flex-direction: column;
	align-items: center;
	height: 100vh;
	width: 25.4rem;
	border-right: 0.1rem solid #f2f2f2;
	padding-block-start: 5rem;
	display: none;
	position: fixed;
	transform: perspective(70rem);
	transform-origin: bottom left;
	transition: transform 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19);
	overflow-y: auto;

	&.open {
		display: flex;
		right: 0;
		border-left: 0.1rem solid #f2f2f2;
		z-index: 10;
		background-color: var(--color-quatery);
		min-height: 100%;
		position: fixed;
	}

	@media (min-width: 1014px) {
		display: flex;
		left: 0rem;
		position: fixed;
	}
`;

export const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-block-end: 7.3rem;
`;

export const StyledLogo = styled(Logo)`
	margin-bottom: 8rem;

	nav.open & {
		display: block;
	}
	@media (max-width: 700px) {
		margin-bottom: 4rem;
	}
`;

export const StyledLink = styled(NavLink)`
	display: flex;
	align-items: center;
	text-decoration: none;
	font-weight: 600;
	color: ${props => props.color || '#333333'};
	font-size: 1.4rem;
	padding: 1rem 2.7rem;

	&:not(:last-child) {
		margin-block-end: 1.2rem;
	}

	& > span {
		margin-inline-start: 1.475rem;
	}
`;

export const LogoutButton = styled('button')`
	display: flex;
	align-items: center;
	text-decoration: none;
	font-weight: 600;
	color: ${props => props.color || '#333333'};
	font-size: 1.4rem;
	padding: 1rem 2.7rem;
	cursor: pointer;
	background-color: transparent;
	outline: none;
	border: none;

	&:not(:last-child) {
		margin-block-end: 1.2rem;
	}

	& > span {
		margin-inline-start: 1.475rem;
	}
`;

export const StyledLogOut = styled(IoIosLogOut)`
	font-size: 2.5rem;
	color: #bdbdbd;
`;
