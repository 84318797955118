import styled from 'styled-components';

export const StyledGetStarted = styled.div`
	background: var(--color-primary-bg);
	color: var(--color-quatery);
	padding-block: 2.4rem;
	padding-inline: 2rem;
	margin-block-end: 6.5rem;

	@media (min-width: 1014px) {
		padding-block: 4.4rem;

	}

	h4 {
		font-style: normal;
		font-weight: bold;
		font-size: var(--font-s-28);
		color: var(--color-quatery);
	}

	p {
		font-style: normal;
		font-weight: normal;
		font-size: var(--font-s-18);
		color: var(--color-quatery);
	}
`;
