import { lazy, Suspense } from 'react';
import Fallback from '../custom/fallback';
import DashboardLayout from './dashboardLayout/dashboardLayout';

const Main = lazy(() => import('../components/updateDocument/main/main'));
interface UpdateDocumentMain{
	handleNext :()=>void;
	handleBack?:()=>void;
}
const UpdateDocument= ({
	handleBack,
	handleNext,
  }: UpdateDocumentMain) => {
	return (
		<DashboardLayout heading='CyberPay Merchant / Get Started  /  Upload Documents'>
			<Suspense fallback={<Fallback />}>
				<Main handleNext={handleNext} handleBack={handleBack}/>
			</Suspense>
		</DashboardLayout>
	);
};

export default UpdateDocument;
