import { StyledGetStarted } from '../getStarted/styledGetStarted';

interface ComponentProps {
	heading: string;
	description: string;
}

const GetStarted: React.FC<ComponentProps> = ({ heading, description }) => {
	return (
		<StyledGetStarted>
			<h4>{heading}</h4>
			<p>{description}</p>
		</StyledGetStarted>
	);
};

export default GetStarted;
