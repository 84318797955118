import { Route, Redirect } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

interface Props {
	component: any;
	path: string;
	exact: boolean;
}

const ProtectRoute = ({ component: Component, ...rest }: Props) => {
	const { token } = useAuth();

	return (
		<Route
			{...rest}
			render={props =>
				token ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/',
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
};

export default ProtectRoute;
