import { useRef, FC } from 'react';
import { Avatar } from '@mui/material';
import { StyledHeading, StyledSpan, StyledHeader, Wrapper, HamburgerMenu, Overlay } from './StyledHeader';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
interface ComponentProps {
	heading?: string;
}

const Header: FC<ComponentProps> = ({ heading }) => {
	const state = useSelector((state: RootState) => state);
	const username: string = state?.profile?.username?.split('@')[0];

	let text;

	if (heading) {
		const splittedString = heading?.split('/');

		//dynamically divide the text into tabs and add color depending on the position of the text
		text = splittedString?.map((text, index) => {
			if (index === splittedString?.length - 1) {
				return <StyledSpan key={index}>{text}</StyledSpan>;
			} else {
				return <span key={index}>{text} / </span>;
			}
		});
	}

	const ref = useRef<any>();

	const toggleMenu = () => {
		if (!ref.current) return;
		ref.current.classList.toggle('open');
		ref.current.parentNode.parentNode.previousElementSibling.classList.toggle('open');
	};

	return (
		<StyledHeader>
			<HamburgerMenu aria-labelledby='hamburger-menu' role='switch' onClick={toggleMenu} ref={ref} tab-index='-1' />
			<Overlay aria-labelledby='overlay' />
			{heading && <StyledHeading>{text}</StyledHeading>}
			<Wrapper>
				<Avatar alt='Avatar image' />
				<p>{username}</p>
			</Wrapper>
		</StyledHeader>
	);
};

export default Header;
