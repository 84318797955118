import { FC } from 'react';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

const Fallback: FC = () => {
	return (
		<Main>
			<CircularProgress color='inherit' />
		</Main>
	);
};

export default Fallback;

const Main = styled('main')`
	display: flex;
	align-items: center;
	justify-content: center;
	min-block-size: 100vh;
	font-size: 1.6rem;
	color: #3e38de8f;
`;
