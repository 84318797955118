import { FC } from 'react';
import { Steps } from 'antd';

interface formStepperI {
	currentStep: any;
	percent: number;
	handleChange: any;
	isDocumentUpdateCompleted: boolean;
	isCreateAccountCompleted: boolean;
	isUpdateProfileCompleted: boolean;
}

const { Step } = Steps;

type Status = 'wait' | 'process' | 'finish' | 'error';

const FormStepper: FC<formStepperI> = ({
	currentStep,
	percent,
	handleChange,
	isCreateAccountCompleted,
	isUpdateProfileCompleted,
	isDocumentUpdateCompleted,
}) => {
	const steps = [
		{
			title: 'Create Account',
			content: 'Create-Account',
			status: currentStep == '0' ? 'process' : isCreateAccountCompleted ? 'finish' : 'wait',
		},
		{
			title: 'Update Profile',
			content: 'Update-Profile',
			status: currentStep == '1' ? 'process' : isUpdateProfileCompleted ? 'finish' : 'wait',
		},
		{
			title: 'Upload Document',
			content: 'Upload-Document',
			status: currentStep == '2' ? 'process' : isDocumentUpdateCompleted ? 'finish' : 'wait',
		},
		{
			title: 'Account Summary',
			content: 'Account-Summary',
			status: currentStep == '3' ? 'process' : 'wait',
		},
	];

	return (
		<Steps current={currentStep} percent={percent} type='default' onChange={value => handleChange(value)}>
			{steps.map(item => (
				<Step key={item.title} title={item.title} status={item.status as Status} />
			))}
		</Steps>
	);
};

export default FormStepper;
