import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import {
	updateBusinesses,
	updateBusiness,
	updateIntegration,
	updateIntegrations,
	updateProfile,
	updateStatus,
	updateWallet,
	logout,
} from '../services/businessSlice';

export const useActionCreator = () => {
	const dispatch = useDispatch();

	return bindActionCreators(
		{
			updateBusinesses,
			updateBusiness,
			updateIntegration,
			updateIntegrations,
			updateProfile,
			updateStatus,
			updateWallet,
			logout,
		},
		dispatch
	);
};
