import { lazy, Suspense } from 'react';
import Fallback from '../custom/fallback';
import DashboardLayout from './dashboardLayout/dashboardLayout';

const Main = lazy(() => import('../components/testIntegration/main/main'));

const TestIntegration = () => {
	return (
		<DashboardLayout heading='CyberPay Merchant / Get Started  /  Update Documents'>
			<Suspense fallback={<Fallback />}>
				<Main />
			</Suspense>
		</DashboardLayout>
	);
};

export default TestIntegration;
