import React, { useState, useEffect, useMemo } from 'react';

interface TokenModel {
	id: string;
	type: string;
	expiryDate: string;
	token: string;
	twoFactorEnabled: boolean;
	isDefaultPassword?: boolean;
}

interface TwoFactorModel {
	id: string;
	rememberMe: boolean;
}
interface ReturnType {
	token: string;
	setTokenModel: React.Dispatch<React.SetStateAction<TokenModel[] | []>>;
	setToken: React.Dispatch<React.SetStateAction<string>>;
	setTwoFactorModel: React.Dispatch<React.SetStateAction<TwoFactorModel>>;
	twoFactorModel: TwoFactorModel;
}

const useAuth = (): ReturnType => {
	const [tokenModel, setTokenModel] = useState(JSON.parse(localStorage.getItem('auth') || '[]') as TokenModel[]);
	const [twoFactorModel, setTwoFactorModel] = useState(
		JSON.parse(localStorage.getItem('twoFactorAuth') || '{}') as TwoFactorModel
	);
	const [token, setToken] = useState(() => tokenModel[0]?.token);

	const memoizedTwoFactorModel = useMemo(() => twoFactorModel, [twoFactorModel]);
	const memoizedTokenModel = useMemo(() => tokenModel, [tokenModel]);

	useEffect(() => {
		localStorage.setItem('auth', JSON.stringify(tokenModel));
		localStorage.setItem('twoFactorAuth', JSON.stringify(twoFactorModel));

		if (tokenModel?.length > 0) {
			setToken(tokenModel[0]?.token);
		}

		// eslint-disable-next-line
	}, [memoizedTokenModel, memoizedTwoFactorModel]);

	return { token, twoFactorModel, setTokenModel, setTwoFactorModel, setToken };
};

export default useAuth;
