import { lazy, Suspense } from 'react';
import Fallback from '../custom/fallback';

const AppGuide = lazy(() => import('../components/appGuide/appGuide'));

const Docs = () => {
	return (
		<Suspense fallback={<Fallback />}>
			<AppGuide />
		</Suspense>
	);
};

export default Docs;
