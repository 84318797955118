import { lazy, Suspense } from 'react';

import Fallback from '../custom/fallback';

const Main = lazy(() => import('../components/signUp/main'));

const signUp = () => {
	return (
		<Suspense fallback={<Fallback />}>
			<Main />
		</Suspense>
	);
};

export default signUp;
