import styled from 'styled-components';

export const StyledLayout = styled.div`
	display: flex;
	height: 100vh;
`;

export const StyledMain = styled.div`
	width: 100%;
	padding-inline: 2rem;
	padding-block: 2rem;
	height: 100%;
	

	@media (min-width: 1014px) {
		padding-inline: 30rem 4.9rem;
		padding-block: 4.3rem 0rem;
	}
`;
