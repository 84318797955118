import React, { ReactNode, FC } from 'react';
import Header from '../../components/dashboardLayout/header/header';
import SideBar from '../../components/dashboardLayout/sideBar/sideBar';
import { StyledLayout, StyledMain } from '../dashboardLayout/styledDashboardLayout';
interface ComponentProps {
	children: React.ReactNode;
	heading?: string;
	SearchBar?: ReactNode;
}

const DashboardLayout: FC<ComponentProps> = ({ children, heading }) => {
	return (
		<StyledLayout>
			<SideBar />
			<StyledMain>
				<Header heading={heading} />
				{children}
			</StyledMain>
		</StyledLayout>
	);
};

export default DashboardLayout;
