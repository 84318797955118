import { Spin } from 'antd';
import DashboardLayout from './dashboardLayout/dashboardLayout';
import FormStepper from '../components/formStepper';
import GetStarted from '../components/createAccount/main/getStarted/GetStarted';
import React, { useState, Suspense } from 'react';
import { useQuery } from 'react-query';
import apiCall from '../utils/apiCall';

import Fallback from '../custom/fallback';

const CreateAccountComponent = React.lazy(() => import('../components/createAccount/main/main'));

const CreateProfileComponent = React.lazy(() => import('../components/updateProfile/main/main'));

const UploadDocumentsComponenet = React.lazy(() => import('../components/updateDocument/main/main'));

const AccountSummaryComponent = React.lazy(() => import('../components/updateDocument/accountSummary/accountSummary'));

const getStepCompletionStatus = async () => {
	return (await apiCall({}).get('businesses/onboardTask'))?.data?.data;
};

const CreateAccount = () => {
	const [currentstepper, setCurrentstepper] = useState({ currentStep: '0', percent: 70 });

	const { data, isLoading, isError } = useQuery('get-started-status', getStepCompletionStatus);

	const { currentStep, percent } = currentstepper;

	const handleStepperChange = (value: string) => {
		if (parseInt(value) == 1) {
			if (!data?.createAcountCompleted) return;
		}

		if (parseInt(value) == 2) {
			if (!data?.createAcountCompleted || !data?.updateProfileCompleted) return;
		}

		if (parseInt(value) == 3) {
			if (!data?.createAcountCompleted || !data?.updateProfileCompleted || !data?.uploadDocumentCompleted) return;
		}
		setCurrentstepper({ ...currentstepper, currentStep: value });
	};

	const handleBackUpdateDocument = () => {
		setCurrentstepper({ ...currentstepper, currentStep: '0' });
	};

	const handleNextUpdateDocument = () => {
		setCurrentstepper({ ...currentstepper, currentStep: '2' });
	};

	const handleNextCreateAccount = () => {
		setCurrentstepper({ ...currentstepper, currentStep: '1' });
	};

	const handleUpdateDocumentNext = () => {
		if (!data?.createAcountCompleted || !data?.updateProfileCompleted || !data?.uploadDocumentCompleted) return;

		setCurrentstepper({ ...currentstepper, currentStep: '3' });
	};

	const handleUpdateDocumentBack = () => {
		setCurrentstepper({ ...currentstepper, currentStep: '1' });
	};

	const handleAccountSummaryBack = () => {
		setCurrentstepper({ ...currentstepper, currentStep: '2' });
	};

	if (isLoading) return <Spin />;
	if (isError) return <p className='error'>could not retrieve get start progress status</p>;

	const renderCurrentStep = (currentStep: number) => {
		switch (currentStep) {
			case 0:
				return (
					<Suspense fallback={<Fallback />}>
						<CreateAccountComponent handleNext={handleNextCreateAccount} />
					</Suspense>
				);

			case 1:
				return (
					<Suspense fallback={<Fallback />}>
						<CreateProfileComponent handleBack={handleBackUpdateDocument} handleNext={handleNextUpdateDocument} />
					</Suspense>
				);

			case 2:
				return (
					<Suspense fallback={<Fallback />}>
						<UploadDocumentsComponenet
							handleBack={handleUpdateDocumentBack}
							handleNext={handleUpdateDocumentNext}
						/>
					</Suspense>
				);

			case 3:
				return (
					<Suspense fallback={<Fallback />}>
						<AccountSummaryComponent handleBack={handleAccountSummaryBack} />
					</Suspense>
				);
			default:
				return (
					<Suspense fallback={<Fallback />}>
						<CreateAccountComponent handleNext={handleNextCreateAccount} />
					</Suspense>
				);
		}
	};

	return (
		<DashboardLayout heading='CyberPay Merchant / Get Started  /  Create Account'>
			<GetStarted heading='Get Started' description='To get started provide information ' />
			<FormStepper
				currentStep={currentStep}
				percent={percent}
				handleChange={handleStepperChange}
				isCreateAccountCompleted={data?.createAcountCompleted}
				isUpdateProfileCompleted={data?.updateProfileCompleted}
				isDocumentUpdateCompleted={data?.uploadDocumentCompleted}
				key={currentStep}
			/>
			{renderCurrentStep(parseInt(currentstepper.currentStep))}
		</DashboardLayout>
	);
};

export default CreateAccount;
