import { lazy, Suspense } from 'react';
import Fallback from '../custom/fallback';
const Main = lazy(() => import('../components/createNewPassword/main'));

const CreateNewPassword = () => {
	return (
		<Suspense fallback={<Fallback />}>
			<Main />
		</Suspense>
	);
};

export default CreateNewPassword;
